<template>
  <vs-prompt
    @cancel="resetForm"
    @accept="sendCarData"
    @close="close"
    :title="title"
    :accept-text="acceptText"
    :active.sync="activeModal"
    :is-valid="validData"
  >

    <div class="mb-3">
      <vs-input
        v-validate=""
        data-vv-validate-on="blur"
        data-vv-as="car name"
        name="attribute"
        v-model="carAttribute"
        class="w-full"
        placeholder=""
      />
      <vs-alert :active="!validData" color="danger" icon="new_releases" >
        Enter valid {{attribute}}
      </vs-alert>
    </div>
    
  </vs-prompt>
</template>

<script>
export default {
  props: ["attribute"],
  data() {
    return {
      activeModal: true,
      title: `Car ${this.attribute}`,
      carAttribute: "",
      acceptText: "Enter",
      attributeError: "",
    }
  },
  methods: {
    close() {
      this.activeModal = true;
      this.updateModalStatus();
    },
    resetForm() {
      this.activeModal = true;
      this.updateModalStatus();
    },

    updateModalStatus() {
      this.$emit("prop-car-update", false);
    },
    sendCarData() {
      let key = this.attribute;
      let carData = {};
      carData[key] = this.carAttribute;
      this.$emit("prop-car-update", carData);
    },
  },
    
  computed:{
    validData(){
      if (this.attribute === "year") {
        let valid = this.carAttribute.match(/^\d{4}$/) !== null;
        return valid ? true : false
      }
      if (this.attribute === "make" || this.attribute === "model" || this.attribute === "bodyType" || this.attribute === "transmission" || this.attribute === "engineCapacity" || this.attribute === "cylinder") {
        return (this.carAttribute.length > 3)
      }
      return true
    }
  },
  created() {
    this.activeModal = true;
  },
}
</script>